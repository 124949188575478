import { MobileContentPoolAddArticle } from 'components/content-pool/add-article/mobile/add-article'
import { ContentPoolAddSource } from 'components/content-pool/add-source'
import { AddArticlePageModal } from 'components/content-pool/addArticlePageModal'
import { ContentPoolArticleHistory } from 'components/content-pool/article-history'
import { ContentPoolEditArticle } from 'components/content-pool/edit-article/component'
import { MobileContentPoolEditArticle } from 'components/content-pool/edit-article/mobile/edit-article'
import { ContentPoolEditSource } from 'components/content-pool/edit-source/edit-source'
import { ContentPoolAddFacebookSource } from 'components/content-pool/facebook/add-source'
import { ContentPoolFilters } from 'components/content-pool/filters'
import { ContentPoolAddGoogleSource } from 'components/content-pool/google/add-source'
import { ContentPoolAddInstagramSource } from 'components/content-pool/instagram/add-source'
import { ContentTool } from 'components/content-pool/library/contentTool'
import { ContentPoolAddLinkedInSource } from 'components/content-pool/linkedin/add-source'
import { MobileContentPoolAddSource } from 'components/content-pool/mobile/add-source'
import { MobileContentLibrary } from 'components/content-pool/mobile/library'
import { MobileContentPoolSourcesList } from 'components/content-pool/mobile/sources-list'
import { MobileUpcomingArticles } from 'components/content-pool/mobile/upcoming-articles'
import { ContentPoolAddPocketSource } from 'components/content-pool/pocket/add-source'
import { ContentPoolReservedArticles } from 'components/content-pool/reserved-articles'
import { ContentPoolAddRSSSource } from 'components/content-pool/rss/add-source'
import { ContentPoolSourceSections } from 'components/content-pool/sections/component'
import { ContentPoolEditSection } from 'components/content-pool/sections/edit-section'
import { ContentPoolSourcesList } from 'components/content-pool/sources-list'
import * as Suggest from 'components/content-pool/suggested/components'
import * as SuggestArticles from 'components/content-pool/suggested/articles'
import * as SuggestContent from 'components/content-pool/suggested/content'
import * as SuggestSources from 'components/content-pool/suggested/sources'
import { ContentPoolAddTopicSource } from 'components/content-pool/topic/add-source'
import { ContentPoolUpcomingArticles } from 'components/content-pool/upcoming-articles'
import { ContentPoolAddUpContentSource } from 'components/content-pool/upcontent/add-source'
import { ContentPoolAddYoutubeSource } from 'components/content-pool/youtube/add-source'
import { AddArticle } from 'components/icons/addArticle'
import { AddSource } from 'components/icons/addSource'
import { Books } from 'components/icons/books'
import { ContentHistory } from 'components/icons/contentHistory'
import { Filters } from 'components/icons/filters'
import { Reserved } from 'components/icons/reserved'
import { Sources } from 'components/icons/sources'
import { PanelBarOption } from 'components/panel-bar/types'
import * as Globals from 'generic/globals'
import * as GA from 'google-analytics'
import * as React from 'react'
import { Roles } from 'shared/constants'
import {BillingPlan, BillingPlanDetailCode, canAccessPlanFeature} from 'shared_server_client/types/billing_plan'
import { ContentPoolAddPodcastSource } from 'components/content-pool/podcast/add-source'
import { Sections } from '../../components/icons/sections'
import { Upcoming } from '../../components/icons/upcoming'

const ARTICLES_CATEGORY: string = 'ARTICLES'
const SOURCES_CATEGORY: string = 'SOURCES'
const RESERVED_SECTION_NAME: string = 'Reserve'
const AI_GENERATED_SOURCE_NAME: string = 'Add Suggested source'
const AI_GENERATED_SUGGESTED_SOURCES: string = 'Suggested sources'
const AI_GENERATED_SUGGESTED_ARTICLES: string = 'Suggested articles'
const AI_GENERATED_SUGGESTED_CONTENT: string = 'Suggested content'
const imgElement = <img src={Globals.CONTENT_POOL_OPTION_IMG} className="panel-generic-item-icon"/>

const contentHistory = <ContentHistory svgwidth={34.7} svgheight={32.9}/>
const addArticle = <AddArticle svgwidth={33.5} svgheight={40.2}/>
const sources = <Sources svgwidth={29.8} svgheight={41.1}/>
const addSource = <AddSource svgwidth={35.1} svgheight={41.1} />
const filters = <Filters svgwidth={25.3} svgheight={33.7} />
const library = <Books svgwidth={25.3} svgheight={33.7} />
const reserved = <Reserved svgwidth={35} svgheight={31} />
const sectionsIcon = <Sections svgwidth={25.3} svgheight={33.7} />
const upcoming = <Upcoming svgwidth={25.3} svgheight={33.7} />

export const mobileSections: PanelBarOption[] = [
  {
    category: ARTICLES_CATEGORY,
    component: MobileUpcomingArticles,
    name: 'Articles',
    route: '',
    icon: imgElement,
  },
  {
    category: ARTICLES_CATEGORY,
    component: MobileContentPoolAddArticle,
    hide: true,
    name: 'Add Article',
    route: 'addarticle',
  },
  {
    category: '',
    component: MobileContentPoolEditArticle,
    hide: true,
    name: 'Edit Article',
    route: 'editarticle',
  },
  {
    category: SOURCES_CATEGORY,
    component: MobileContentPoolSourcesList,
    name: 'Sources',
    route: 'sources',
    icon: sources,
  },
  {
    category: SOURCES_CATEGORY,
    component: MobileContentPoolAddSource,
    hide: true,
    name: 'Add Source',
    route: 'addsource',
  },
  {
    category: SOURCES_CATEGORY,
    component: ContentPoolAddRSSSource,
    hide: true,
    name: 'Add RSS',
    route: 'add-rss',
  },
  {
    category: SOURCES_CATEGORY,
    component: ContentPoolAddFacebookSource,
    hide: true,
    name: 'Add Facebook',
    route: 'add-facebook',
  },
  {
    category: SOURCES_CATEGORY,
    component: ContentPoolAddYoutubeSource,
    hide: true,
    name: 'Add YouTube',
    route: 'add-youtube',
  },
  {
    category: '',
    component: MobileContentLibrary,
    hide: true,
    name: 'Content Library',
    route: 'library',
  },
]

export const allSections: PanelBarOption[] = [
  {
    category: '',
    component: ContentPoolAddRSSSource,
    hide: true,
    icon: imgElement,
    name: 'ADD RSS',
    route: 'add-rss',
  },
  {
    category: '',
    component: ContentPoolAddFacebookSource,
    hide: true,
    icon: imgElement,
    name: 'ADD FACEBOOK',
    route: 'add-facebook',
  },
  {
    category: '',
    component: ContentPoolAddInstagramSource,
    hide: true,
    icon: imgElement,
    name: 'ADD INSTAGRAM',
    route: 'add-instagram',
  },
  {
    category: '',
    component: ContentPoolAddLinkedInSource,
    hide: true,
    icon: imgElement,
    name: 'ADD LINKEDIN',
    route: 'add-linkedin',
  },
  {
    category: '',
    component: ContentPoolAddPocketSource,
    hide: true,
    name: 'Add Pocket RSS feed',
    route: 'add-pocket',
  },
  {
    category: '',
    component: ContentPoolAddUpContentSource,
    hide: true,
    name: 'Add UpContent RSS feed',
    route: 'add-upcontent',
  },
  {
    category: '',
    component: ContentPoolAddGoogleSource,
    hide: true,
    name: 'Add Google News',
    route: 'add-google',
  },
  {
    category: '',
    component: ContentPoolAddTopicSource,
    hide: true,
    name: 'Add Articles by Topic',
    route: 'add-topic',
  },
  {
    category: '',
    component: ContentPoolAddPodcastSource,
    hide: true,
    name: 'Add Podcast',
    route: 'add-podcast',
  },
  {
    category: '',
    component: Suggest.AnalyzeContentComponent,
    hide: true,
    name: AI_GENERATED_SOURCE_NAME,
    route: 'suggest',
  },
  {
    category: '',
    component: SuggestSources.SuggestedSources,
    hide: true,
    name: AI_GENERATED_SUGGESTED_SOURCES,
    route: 'suggest-sources',
  },
  {
    category: '',
    component: SuggestArticles.SuggestArticlesComponent,
    hide: true,
    name: AI_GENERATED_SUGGESTED_ARTICLES,
    route: 'suggest-articles',
  },
  {
    category: '',
    component: SuggestContent.SuggestedContentComponent,
    hide: true,
    name: AI_GENERATED_SUGGESTED_CONTENT,
    route: 'suggest-content',
  },
  {
    category: '',
    component: ContentPoolAddYoutubeSource,
    hide: true,
    icon: imgElement,
    name: 'ADD YOUTUBE',
    route: 'add-youtube',
  },
  {
    category: '',
    component: ContentPoolUpcomingArticles,
    icon: upcoming,
    name: 'Upcoming',
    route: '',
  },
  {
    category: '',
    component: AddArticlePageModal,
    icon: addArticle,
    name: '+ Add article',
    route: 'addarticle',
  },
  {
    category: '',
    component: ContentPoolArticleHistory,
    icon: contentHistory,
    name: 'History',
    route: 'history',
  },
  {
    category: '',
    component: ContentPoolReservedArticles,
    icon: reserved,
    name: RESERVED_SECTION_NAME,
    route: 'reserved',
  },
  {
    category: '',
    component: ContentPoolAddSource,
    icon: addSource,
    name: '+ Add Source',
    route: 'addsource',
  },
  {
    category: '',
    component: ContentPoolSourcesList,
    icon: sources,
    name: 'Sources',
    route: 'sources',
  },
  {
    category: '',
    component: ContentPoolSourceSections,
    icon: sectionsIcon,
    name: 'Sections',
    route: 'sections',
    feature_name: BillingPlanDetailCode.SECTIONS,
  },
  {
    category: '',
    component: ContentPoolFilters,
    icon: filters,
    name: 'Filters',
    route: 'filters',
  },
  {
    category: '',
    component: ContentPoolEditArticle,
    hide: true,
    name: 'Edit Article',
    route: 'editarticle',
  },
  {
    category: '',
    component: ContentPoolEditSource,
    hide: true,
    name: 'Edit Source',
    route: 'editsource',
  },
  {
    category: '',
    component: ContentPoolEditSection,
    hide: true,
    name: 'Edit Section',
    route: 'editsection',
  },
  {
    category: '',
    component: ContentTool,
    event: {
      name: GA.EventNames.OpenContentTool,
      payload: {
        source: 'left-menu',
      },
    },
    hide: false,
    name: 'Content Library',
    route: 'library',
    icon: library,
  },
]

export const sections = (role: string, billingPlan: BillingPlan): PanelBarOption[] => {
  return allSections.filter((item) => {
    if (item.feature_name) {
      item.hasFeature = canAccessPlanFeature(item.feature_name, billingPlan)
      return true
    }
    if (item.name === RESERVED_SECTION_NAME) {
      return role === Roles.super_admin ||
        canAccessPlanFeature(BillingPlanDetailCode.RESERVE_CONTENT, billingPlan)
    } else if (item.name === AI_GENERATED_SUGGESTED_SOURCES
      || item.name === AI_GENERATED_SUGGESTED_ARTICLES) {
      return canAccessPlanFeature(BillingPlanDetailCode.AI_RECOMMENDATIONS, billingPlan)
    } else {
      return true
    }
  })
}
